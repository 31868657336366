<template>
  <div class="container">
    <!-- 基础信息 -->
    <el-descriptions title="基础信息" :column="4" border size="medium">
      <el-descriptions-item label="打包批次号">{{
        formData.billpackNumber
      }}</el-descriptions-item>
      <el-descriptions-item label="会员号"
        >{{ formData.memberId }}
      </el-descriptions-item>
      <el-descriptions-item label="会员名称"
        >{{ formData.nickname }}
      </el-descriptions-item>
      <el-descriptions-item label="所属仓库"
        >{{ formData.storageName }}
      </el-descriptions-item>
      <el-descriptions-item label="运输方式"
        >{{ formData.channelTransportName }}
      </el-descriptions-item>
      <el-descriptions-item label="渠道">{{
        formData.channelName
      }}</el-descriptions-item>
      <el-descriptions-item label="渠道代理"
        >{{ formData.customerAgentName }}
      </el-descriptions-item>
      <el-descriptions-item label="转单号"
        >{{ formData.channelNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="递送时间"
        >{{ formData.deliveryTime }}
      </el-descriptions-item>
      <el-descriptions-item label="快递数量"
        >{{ formData.courierCount }}
      </el-descriptions-item>
      <el-descriptions-item label="打包件数"
        >{{ formData.packCount }}
      </el-descriptions-item>
      <el-descriptions-item label="打包重量"
        >{{ formData.packWeight }}
      </el-descriptions-item>
      <el-descriptions-item label="打包材积"
        >{{ formData.dataVolume }}
      </el-descriptions-item>
      <el-descriptions-item label="打包体积"
        >{{ formData.packVolum }}
      </el-descriptions-item>
      <el-descriptions-item label="申请打包备注"
        >{{ formData.billpackComment }}
      </el-descriptions-item>
      <el-descriptions-item label="打包确认备注"
        >{{ formData.billstorepackComment }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 收货人信息 -->
    <el-descriptions title="收件人信息" :column="4" border size="medium">
      <el-descriptions-item label="收件人">{{
        formData.consigneeName
      }}</el-descriptions-item>
      <el-descriptions-item label="公司名称"
        >{{ formData.companyName }}
      </el-descriptions-item>
      <el-descriptions-item label="联系电话"
        >{{ formData.receivingPhone }}
      </el-descriptions-item>
      <!-- <el-descriptions-item label="联系手机">{{ formData.receivingPhone }} </el-descriptions-item> -->
      <el-descriptions-item label="国家"
        >{{ formData.chineseName }}
      </el-descriptions-item>
      <el-descriptions-item label="州/省">{{
        formData.provinceName
      }}</el-descriptions-item>
      <el-descriptions-item label="城市"
        >{{ formData.cityName }}
      </el-descriptions-item>
      <el-descriptions-item label="邮政编码"
        >{{ formData.postCode }}
      </el-descriptions-item>
      <el-descriptions-item label="详细地址"
        >{{ formData.addressee }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 财务信息 -->
    <el-descriptions title="财务信息" :column="4" border size="medium">
      <template slot="extra">
        <el-button type="primary" @click="openWindow" size="small"
          >查看其他费用明细</el-button
        >
      </template>
      <el-descriptions-item label="运费">{{
        formData.freight || 0
      }}</el-descriptions-item>
      <el-descriptions-item label="其他费用"
        >{{ formData.otherFee || 0 }}
      </el-descriptions-item>
      <el-descriptions-item label="燃油"
        >{{ formData.fuel || 0 }}
      </el-descriptions-item>
      <el-descriptions-item label="应收合计"
        >{{ formData.settlementAmount || 0 }}
      </el-descriptions-item>
      <el-descriptions-item label="收款状态"
        >{{ formData.writeoffStatusShow }}
      </el-descriptions-item>
      <el-descriptions-item label="收款备注">{{
        formData.writeoffComment
      }}</el-descriptions-item>
    </el-descriptions>

    <!-- 打包明细 -->
    <div style="margin: 20px 0 4px; font-weight: 700">打包明细</div>
    <el-table
      :header-cell-style="{ background: '#f0f0f0' }"
      :data="tableData"
      border
      stripe
      size="small"
      style="width: 100%"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column
        prop="subNumber"
        align="center"
        label="包号"
        :show-overflow-tooltip="true"
        min-width="160"
      >
      </el-table-column>
      <el-table-column
        prop="weight"
        align="center"
        :show-overflow-tooltip="true"
        label="打包重量"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="dataVolume"
        align="center"
        label="打包材积"
        :show-overflow-tooltip="true"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="volum"
        align="center"
        label="打包体积"
        :show-overflow-tooltip="true"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="结算重量"
        :show-overflow-tooltip="true"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="渠道计费单价"
        :show-overflow-tooltip="true"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="volumDetail"
        align="center"
        label="外箱尺寸(厘米)"
        :show-overflow-tooltip="true"
        min-width="160"
      >
      </el-table-column>
    </el-table>
    <!-- 快递明细 -->
    <div style="margin: 20px 0 4px; font-weight: 700">快递明细</div>
    <el-table
      :header-cell-style="{ background: '#f0f0f0' }"
      :data="tableData2"
      border
      stripe
      size="small"
      style="width: 100%"
      show-summary
      :summary-method="getSummaries2"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column
        prop="courierNumber"
        align="center"
        label="快递单号"
        :show-overflow-tooltip="true"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop="packageWeight"
        align="center"
        :show-overflow-tooltip="true"
        label="入库重量"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="itemName"
        align="center"
        label="物品名称"
        :show-overflow-tooltip="true"
        min-width="100"
      >
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="单价"
        :show-overflow-tooltip="true"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="boxCount"
        align="center"
        label="数量"
        :show-overflow-tooltip="true"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="unitPrices"
        align="center"
        label="货值"
        :show-overflow-tooltip="true"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="comment"
        align="center"
        label="备注"
        :show-overflow-tooltip="true"
        min-width="160"
      >
      </el-table-column>
    </el-table>
    <!-- <paging
      ref="pags"
      :pageNum="pageStart_log"
      :total="pageCount_log"
      :sizeList="sizeList"
      :size="pageTotal_log"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    </paging> -->
    <!-- 展示费项 -->
    <el-dialog
      title="其他费用"
      :visible.sync="showOtherFee"
      width="50%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <div class="formbox" style="width: 100%; min-width: 400px">
        <!-- 费项 -->
        <div class="form_fq">
          <el-table :data="tableData3" border stripe style="width: 100%">
            <el-table-column
              fixed="left"
              label="序号"
              type="index"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="serviceName"
              label="应收费项"
              min-width="180"
            ></el-table-column>
            <el-table-column
              prop="serviceCount"
              label="数量"
              min-width="180"
            ></el-table-column>
            <el-table-column prop="amount" label="应收金额" min-width="100">
            </el-table-column>
            <!-- <el-table-column prop="isDelete" label="是否可以删除" min-width="100">
            </el-table-column> -->
          </el-table>
          <!-- <div style="margin: 10px">费项合计:{{ allPrice || 0 }}</div> -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showOtherFee = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paging from "@/components/pagings.vue";
import { Api } from "@/assets/js/api";

export default {
  components: { paging },
  data() {
    return {
      tableData: [], //打包明细
      tableData2: [], //快递明细
      tableData3: [], //其他明细
      formData: {},
      showOtherFee: false,
      packageId: "",
      financeInId: "",
      //   pageStart_log: 1,
      //   pageTotal_log: 50,
      //   pageCount_log: 0,
      //   sizeList: [10, 20, 50, 100, 500],
    };
  },
  created() {
    // let packageId = this.$route.query.packageId || "";
    // if (packageId) {
    //   this.packageId = packageId;
    //   this.getData();
    // }
  },
  activated() {
    let packageId = this.$route.query.packageId || "";
    if (packageId) {
      this.packageId = packageId;
      this.getData();
    }
  },
  mounted() {},
  watch: {},
  methods: {
    // 其他费用
    openWindow() {
      this.showOtherFee = true;
      Api.financeInOtherServiceList({
        financeInId: this.financeInId,
      }).then((res) => {
        this.tableData3 = res.data.result || [];
      });
    },
    handleClose() {
      this.showOtherFee = false;
    },

    getData() {
      Api.packDetail({ packageId: this.packageId }).then((res) => {
        let formData = res.data.result || "";
        this.formData = formData;
        if (formData) {
          this.financeInId = formData.financeInId;
          // 快递明细
          if (formData.packageList) {
            this.tableData2 = formData.packageList;
          }
          // 打包明细
          if (formData.storepackDes) {
            this.tableData = formData.storepackDes;
          }
        }
      });
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index == 1) {
          return false;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] += " 元";
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    getSummaries2(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index == 1) {
          return false;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] += " 元";
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-descriptions__header {
    margin-top: 20px;
    margin-bottom: 4px;
  }
  .el-dialog__footer {
    text-align: center;
  }
}
</style>
